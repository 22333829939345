.navbar {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 20;
}

.navbar-logo {
    width: 2.4em;
    margin: 30px 3rem 6px 3rem;
    z-index: 20;
}

.nav-menu {
    z-index: 20;
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
    margin-top: calc(2.4em - 3px);
}

.nav-links {
    color: var(--white);
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
    z-index: 20;
}
.fa-code {
    margin-left: 1rem;
}

.nav-item .active {
    color: var(--white);
}

.nav-icon {
    display: none;
    z-index: 20;
}

.nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after,
.nav-item:focus:after {
    width: 100%;
    background: var(--white);
}

.nav-socials {
    display: none;
}

.nav-change-color {
    display: none;
    transition: display 1s ease;
}


@media screen and (max-width: 960px) {
    .navbar-logo {
        width: 2.4em;
        margin: 20px 16px;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100dvh;
        position: absolute;
        top: -100lvh;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        margin: 0;
        background-color: var(--black);
        font-weight: 600;
        letter-spacing: 1px;
    }

    .nav-menu.active {
        z-index: 20;
        left: 0;
        top: 0;
        opacity: 1;
        transition: all 0.5s ease;
        width: 100vw;
        margin: 0;
        padding: 0;
    }
    .nav-item .active {
        color: var(--white);
        border: none;
    }
    .nav-links {
        margin: 0;
        padding: 0.5em;
        width: 100vw;
        display: table;
        font-size: max(2.5rem, 2vw);
    }

    .nav-icon {
        display: block;
        font-size: 2.4em;
        cursor: pointer;
        color: var(--white);
        padding: 20px 16px;
        z-index: 100;
    }

    .nav-socials {
        display: flex;
        padding: 2.5em;
        width: 80%;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .nav-socials-item {
        padding: 10px;
        cursor: pointer;
    }

    .nav-socials-item:focus,
    .nav-socials-item:hover {
        transform: rotate(15deg);
    }

    .nav-change-color {
        display: flex;
        transition: display 1s ease;
    }
}

/* Nav icon css */
.menu-icon {
    width: 30px;
    position: relative;
    z-index: 20;
    cursor: pointer;
    display: block;
}
.menu-icon__line {
    height: 3px;
    width: 30px;
    display: block;
    background-color: var(--white);
    margin-bottom: 7px;
    cursor: pointer;
    -webkit-transition: background-color 0.5s ease, -webkit-transform 0.2s ease;
    transition: background-color 0.5s ease, -webkit-transform 0.2s ease;
    transition: transform 0.2s ease, background-color 0.5s ease;
    transition: transform 0.2s ease, background-color 0.5s ease, -webkit-transform 0.2s ease;
}
.menu-icon__line-left {
    width: 16.5px;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.menu-icon__line-right {
    width: 16.5px;
    float: right;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
}
.menu-icon:hover .menu-icon__line-left,
.menu-icon:hover .menu-icon__line-right {
    width: 30px;
}

.menu_icon_line_one {
    width: 15px;
    -webkit-transform: translate(2px, 4px) rotate(45deg);
    transform: translate(2px, 4px) rotate(45deg);
}
.menu_icon_line_two {
    background-color: var(--white);
    -webkit-transform: translate(0px, 0px) rotate(-45deg);
    transform: translate(0px, 0px) rotate(-45deg);
}
.menu_icon_line_three {
    width: 15px;
    float: right;
    -webkit-transform: translate(-3px, -3.5px) rotate(45deg);
    transform: translate(-3px, -3.5px) rotate(45deg);
}

.menu-icon:hover .menu_icon_line_one,
.menu-icon:hover .menu_icon_line_three {
    width: 15px;
}
