:root {
    --form-input-size: max(1.2em, 1vw);
    --form-input-radius: 1.2em;
    --form-font: var(--body-font);
}

.contact-form {
    flex-direction: column;
    justify-content: space-around;
    border-radius: 16px;
    margin: 15% 0;
}

.contact-form-child {
    border-radius: var(--form-input-radius);
    font-size: var(--form-input-size);
    font-family: var(--form-font);
    background-color: #f8f8f8;
    width: 85%;
    margin: 1%;
    text-align: center;
    padding: 2%;
    border: var(--white) 1px solid;
}

.contact-submit-btn {
    cursor: pointer;
    font-family: var(--form-font);
    width: 85%;
    border-radius: var(--form-input-radius);
    margin: 2% 1%;
    font-size: var(--form-input-size);
    text-align: center;
    color: var(--white);
    font-weight: 800;
    text-transform: capitalize;
    border: var(--white) 1px solid;
    padding: 2%;
    background-color: transparent;
}

.contact-submit-btn:hover,
.contact-submit-btn:focus {
    background-color: var(--white);
    color: var(--black);
}

@media screen and (min-width: 768px) {
    .contact-page {
        width: 100%;
    }

    .contact-page-container {
        height: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .contact-title {
        margin: 1%;
    }

    .contact-form {
        width: 50%;
    }

    .contact-form {
        flex-direction: column;
        justify-content: space-around;
        border-radius: 16px;
        margin: 2% 0;
    }
}