/* Edit variables here to ensure timeline doesn't mess up */
:root {
    --timelineWidth: 2px;
    --timelineCircleWidth: 1.4em;
    --timeLineLeftMargin: 8%;
    --timelineItemHeight: 40%;
    --gapBetween: 10px;

    --textItemSize: 75%;
    --textTitleSize: 100%;
    --textBodySize: 85%;
}

.about-timeline-container {
    display: flex;
    margin: 0 0;
}

.about-timeline {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 0;
}

.timeline-col {
    order: 2;
    margin-left: 1em;
}

/* The line of the timeline */
.about-timeline:before {
    content: '';
    width: var(--timelineWidth);
    height: calc(100%);
    background-color: var(--white);
    position: absolute;
    left: var(--timeLineLeftMargin);
    border-radius: 20px;
}

.about-timeline-item {
    width: calc(100% - var(--timeLineLeftMargin) * 2);
    height: var(--timelineItemHeight);
    margin-left: 8%;
    margin-bottom: 8%;
    position: relative;
    transform-origin: -0% 50%;
}

.about-timeline-item:nth-child(4) {
    margin-bottom: 0;
}

/* Dot on the timeline */
.about-timeline-item:before {
    content: '';
    border-radius: 50%;
    width: var(--timelineCircleWidth);
    height: var(--timelineCircleWidth);
    background-color: var(--body);
    border: var(--timelineWidth) var(--white) solid;
    position: absolute;
    top: calc(50% - var(--timelineCircleWidth)/2);
    left: calc(-0% - var(--timelineCircleWidth) / 2 + var(--timelineWidth)/2 - var(--timelineWidth));
}

.about-timeline-item-container {
    text-align: left;
    border-radius: 10px;
    padding: 1em;
    color: var(--white);
    background-color: var(--body);
    border: 2px var(--white) solid;
    width: calc(100% - var(--timelineCircleWidth) - var(--gapBetween));
    margin: 0 calc(var(--timelineCircleWidth) + var(--gapBetween));
}

.timeline-header {
    border-radius: var(--itemRadius);
    padding: 10px 4%;
    margin: 2px 0;
}

.about-timeline-item-role {
    font-weight: 500;
}

.about-timeline-item-company {
    font-weight: 900;
    cursor: pointer;
}

.timeline-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 25%;
    width: 4em;
    border-radius: var(--itemRadius);
    background-color: #f8f8f8;
    border: 2px solid black;
    overflow: hidden;
}

.about-timeline-item-logo {
    width: 100%;
    object-fit: cover;
    
}

.timeline-row {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 2px;
}

.timeline-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.timeline-col div {
    width: 100%;
}

.timeline-item-desc li{
    font-size: var(--textBodySize);
    list-style-position: inside;
    padding: 3px 0;
}

.about-timeline-item-dates {
    padding: 5px 10px;
    background-color: var(--white);
    border-radius: var(--itemRadius);
    border: 1px solid var(--white);
    color: var(--black);
    font-size: var(--textItemSize);
    cursor: pointer;
    margin: 4px 2px;
}

.about-timeline-item-bottom {
    display: flex;
    flex-wrap: wrap;
    margin: 4px 0;
}

.timeline-item-tags {
    background-color: var(--white);
    font-size: var(--textItemSize);
    padding: 5px 10px;
    margin: 2px;
    color: var(--black);
    border-radius: var(--itemRadius);
    border: 1px solid var(--white);
}

.hidden-timeline-date {
    display: none;
}

@media screen and (min-width: 768px) { 
    .about-timeline {
        margin: 60px auto;
    }

    .hidden-timeline-date {
        display: initial;
        order: 3;
        margin-left: auto;
        margin-right: 2px;
        background-color: var(--white);
        font-size: var(--textItemSize);
        padding: 5px 10px;
        color: var(--black);
        border-radius: var(--itemRadius);
        border: 1px solid var(--white);
    }

    .timeline-row {
        justify-content: flex-start;
    }

    .timeline-col {
        order: 2;
        margin-left: 1em;
    }

    .about-timeline-item-dates {
        display: none;
    }

    .about-timeline-item {
        margin-bottom: 3%;
    }
}
