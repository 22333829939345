.motion-div {
    width: 100%;
}

.home-page {
    height: 100svh;
    width: 100%;
}

.home-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 90%;
}

.title {
    font-size: max(3em, 5vw);
    font-family: var(--title-font);
    padding: 0 5%; 
    position: absolute;
    top: 35%;
}

.title-gowtham {
    font-weight: 100;
    transition: 0s ease;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 6px;
    color: var(--body);
    text-shadow: -4px 0 var(--white), 0 4px var(--white), 4px 0 var(--white), 0 -4px var(--white);
}

.title-span {
    color: rgba(255, 255, 255, 0.5) !important;
    font-weight: 900;
    text-shadow: none !important;
   
}

.title-text {
    transition: 0s ease;
    font-size: var(--big-font-size);
    font-family: var(--body-font);
    font-weight: 500;
    color: var(--titleOther);
    text-align: center;
}

.typed-text-cursor {
    color: var(--white);
    animation: typing 1s step-end infinite;
}

@keyframes typing {
    0% {
        color: var(--white);
    }
    49% {
        color: var(--white);
    }
    50% {
        color: transparent;
    }
}

.scroll-down {
    position: absolute;
    bottom: 2%;
    display: flex;
    animation: scroll-down-arrow infinite ease 2s;
    cursor: pointer;
    padding: 20px;
}

.scroll-down:focus,
.scroll-down:hover {
    text-shadow: 0px 0px 30px var(--white),0px 0px 30px var(--white),0px 0px 30px var(--white),0px 0px 10px var(--white),0px 0px 10px var(--white);
}

@keyframes scroll-down-arrow {
    0%{
        transform: translateY(10px);
    }
    80%{
        transform:  translateY(0px);
    }
    90%{
        transform:  translateY(1px);
    }
    100%{
        transform: translateY(9px);
    }
}


/* ipad max width */
@media screen and (min-width: 768px) {
    .title {
        top: 40%;
        margin-left: 10%;
        margin-right: 10%;
    }
}
