/* Edit variables here to ensure timeline doesn't mess up */
:root {
    --timelineWidth: 5px;
    --timelineCircleWidth: 1.4em;
    --timeLineLeftMargin: 8%;
    --timelineItemHeight: 40%;
    --gapBetween: 10px;
}

.about-page {
    width: 100%;
}

.about-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.about-section-one {
    flex-direction: column;
    height: 100%;
    border-radius: var(--backgroundRadius);
}

.about-body-container {
    margin: 0 auto;
}

.about-title {
    font-weight: 900;
    -webkit-text-stroke-width: 1.2px;
    -webkit-text-stroke-color: var(--white);
    color: var(--body);
    text-transform: uppercase;
    letter-spacing: -5px;
    font-size: max(3.5em, 5vw);
    margin: 5% 0;
    font-family: var(--title-font);
}

.about-title span {
    color: var(--white);
}

.about-body {
    margin: 10px auto;
    width: 90%;
}

.about-me-pic {
    display: initial;
    width: 100%;
    margin-top: 10px;
    margin-bottom: -3px;
    width: 100%;
    border-radius: var(--backgroundRadius);
}

.about-section-one {
    background-image: linear-gradient(var(--body), var(--black));
}

.about-section-two {
    flex-direction: column;
    width: 100%;
}

.about-title-other {
    display: none;
}

.about-body span {
    font-weight: 900;
    cursor: pointer;
}

.about-body-link {
    font-weight: 900;
    cursor: pointer;
    line-height: 120%;
    text-decoration: none;
    position: relative;
}


.about-body-link:after {
    content: '';
    width: 0;
    position: absolute;
    left: 0;
    bottom: -1px;
    border-width: 0 0 2px;
    border-style: solid;
    transition: width 0.5s ease, background-color 0.5s ease;
}

.about-body-link:hover:after {
    width: 100%;
}

.timeline-title {
    font-weight: 900;
    -webkit-text-stroke-width: 1.2px;
    -webkit-text-stroke-color: var(--white);
    color: var(--white);
    text-transform: uppercase;
    letter-spacing: -5px;
    font-size: max(3.5em, 5vw);
    margin: 12% 0;
    font-family: var(--title-font);
}

.timeline-title span {
    color: var(--body);
}

@media screen and (min-width: 768px) {
    .about-page {
        min-height: 100svh;
    }

    .about-page-container {
        flex-direction: column;
        width: 100%;
        height: 95%;
    }

    .about-section-one {
        background-image: none;
    }

    .about-section-two {
        width: 90%;
    }

    .about-section-one {
        background-color: var(--body);
        color: var(--white);
        border-radius: var(--backgroundRadius);
        height: 100%;
        margin-bottom: 0%;
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: top;
    }
    
    .about-title-container {
        display: flex;
        flex-direction: column;
        margin: 2em 0;
        flex: 1;
    }

    .about-body-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 2em 0;
        flex: 1;
    }

    .about-body {
        text-align: left;
        flex: 1;
    }

    .about-me-pic {
        display: initial;
        width: 30%;
        border-radius: 50%;
        margin: 0.1em 0;
        flex: 1 1 ;
    }

    .about-me-pic img {
        width: 100%;
        border-radius: 50%;
    }

    .about-title {
        -webkit-text-stroke-width: 1.2px;
        -webkit-text-stroke-color: var(--white);
        color: var(--white);
        text-transform: uppercase;
        letter-spacing: -5px;
        font-size: 5.5em;
        margin: 0;
        font-family: var(--title-font);
        line-height: 100%;
    }
    

    .about-title-other {
        display: initial;
        -webkit-text-stroke-width: 1.2px;
        -webkit-text-stroke-color: var(--white);
        color: var(--body);
    }

    .about-title-other span {
        color: var(--white);
    }

    .timeline-title {
        display: none;
    }
}

